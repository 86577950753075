<template>
  <div class="content team" id="team">
    <banner title="Team" :image="5"> </banner>

    <div class="section">
      <div class="content_wrapper">
        <h3>
          The Taproot team is comprised of midstream experts who have extensive
          experience and knowledge in developing optimized assets that support
          our producer client needs.
        </h3>
        <headshot
          v-for="(teamMember, index) in team"
          :key="index"
          :filename="teamMember.filename"
          :name="teamMember.name"
          :title="teamMember.title"
          :id="teamMember.id"
          @navigate="navigate"
        >
        </headshot>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Headshot from "@/components/Headshot.vue";
import TeamData from "@/team_data.json";

export default {
  name: "Team-view",
  components: {
    Banner,
    Headshot,
  },
  data() {
    return {
      team: TeamData,
    };
  },
  methods: {
    navigate(id) {
      this.$router.push({ path: `/team/${id}` });
    },
  },
  mounted() {
    const queryString = window.location.search
      ? window.location.search
      : window.location.hash;
    const scroll = queryString.split("?scroll=") || null;
    const scrollId = scroll[1] || null;

    if (scrollId) {
      const team = document.getElementById("team");
      const member = document.getElementById(scrollId);
      const offsetMember = member.getBoundingClientRect().top;

      const bannerHeight = 120;
      const offsetTeam = team.getBoundingClientRect().top;

      const finalOffset = Math.abs(offsetTeam + bannerHeight - offsetMember);
      window.scrollTo(0, finalOffset);
    } else {
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style lang="scss">
.headshot {
  float: left;
  margin-left: 35px;
}

@media all and (max-width: 700px) {
  .headshot {
    float: none;
    margin: 0px auto;
  }
}
</style>
