<template>
  <div
    :class="['headshot', { visible: visible }]"
    :ref="`headshot_${id}`"
    :id="`headshot_${id}`"
    @click="$emit('navigate', id)"
  >
    <div
      class="image"
      :style="{
        'background-image': `url(${require(`@/assets/images/team/${filename}`)})`,
      }"
    ></div>

    <div class="description">
      <p>
        <span class="name">{{ name }},</span
        ><span class="title">{{ title }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tep-Headshot",
  props: {
    filename: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      offset: null,
    };
  },
  computed: {
    visible() {
      return this.offset ? this.offset < 400 && this.offset > 0 : false;
    },
  },
  methods: {
    scrollListener() {
      const headshot = this.$refs[`headshot_${this.id}`];
      this.offset = headshot.getBoundingClientRect().top;
    },
  },
  created() {
    window.addEventListener("scroll", this.scrollListener);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style lang="scss" scoped>
.headshot {
  font-family: "Nunito Sans";
  position: relative;
  height: 400px;
  width: 167px;
  // box-shadow: 0 5px 5px -5px gray;
  margin-bottom: 15px;

  .image {
    cursor: pointer;
    height: 250px;
    width: 167px;
    border: 1px solid var(--font_color_accent);
    top: 0;
    left: 0;
    background-position: top center;
    background-size: cover;
    background-size: 110%;
    background-repeat: no-repeat;
    box-shadow: 0px 5px 10px -5px gray;
    transition: all 0.5s;
    filter: grayscale(1);
  }
  .name {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .title {
    display: block;
    color: var(--font_color_accent);
    font-size: 18px;
    font-weight: 600;
  }
  &:hover {
    .image {
      filter: grayscale(0);
      background-size: 120%;
    }
  }
}

@media screen and (max-width: 700px) {
  .headshot {
    width: 100%;
    max-width: 250px;
    text-align: center;
    &.visible {
      .image {
        filter: grayscale(0);
        background-size: 120%;
      }
    }
  }
}
</style>
